import { ServiceHttpClients } from '../../types';
import { setupActivityEndpoints } from './activity-endpoints';
import { setupUserEndpoints } from './user-endpoints';
import { setupAuthEndpoints } from './auth-endpoints';
import { setupSwaggerEndpoints } from './swagger-endpoints';
import { setupOrderEndpoints } from './order-endpoints';
import { setupReturnEndpoints } from './return-endpoints';
import { setupStripeEndpoints } from './stripe-endpoints';
import { setupPermissionGroupsEndpoints } from './permission-groups-endpoints';
import { setupPromotionCodeEndpoints } from './promotionCode-endpoints';

export const setupAdminEndpoints = (clients: ServiceHttpClients) => {
  const endpoints = {
    auth: setupAuthEndpoints(clients.backend),
    activity: setupActivityEndpoints(clients.backend),
    user: setupUserEndpoints(clients.backend),
    swagger: setupSwaggerEndpoints(clients.backend),
    order: setupOrderEndpoints(clients.canyon),
    return: setupReturnEndpoints(clients.canyon),
    stripe: setupStripeEndpoints(clients.canyon),
    permissionGroups: setupPermissionGroupsEndpoints(clients.canyon),
    promotionCode: setupPromotionCodeEndpoints(clients.canyon)
  };

  return endpoints;
};
